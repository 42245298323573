const fontFamily = require('./config/fontFamilies');
const fontSize = require('./config/fontSizes');
const colors = require('./config/colors');
const screens = require('./config/screens');
const width = require('./config/width');
const height = require('./config/height');
const minHeight = require('./config/minHeight');
const minWidth = require('./config/minWidth');
const maxWidth = require('./config/maxWidth');
const margin = require('./config/margin');
const padding = require('./config/padding');
const borderRadius = require('./config/borderRadius');
const zIndex = require('./config/zIndex');
const lineHeight = require('./config/lineHeight');
const inset = require('./config/inset');
const boxShadow = require('./config/boxShadow');
const maxHeight = require('./config/maxHeight');
const ringWidth = require('./config/ringWidth');
const borderWidth = require('./config/borderWidth');
const spacing = require('./config/spacing');

module.exports = {
  important: '.chameleon',
  theme: {
    fontFamily,
    colors,
    screens,
    extend: {
      width,
      height,
      minHeight,
      minWidth,
      maxWidth,
      fontSize,
      margin,
      padding,
      borderRadius,
      zIndex,
      lineHeight,
      inset,
      boxShadow,
      maxHeight,
      ringWidth,
      borderWidth,
      spacing,
    },
  },
  content: [
    './chameleon/ui-stack/**/*.js',
    './chameleon/src/**/*.js',
    './chameleon/legacy/**/*.js',
    './chameleon/packages/**/*.js',
    './chameleon/**/*.stories.js',
    './chameleon/ui-stack/**/*.tsx',
    './chameleon/src/**/*.tsx',
    './chameleon/legacy/**/*.tsx',
    './chameleon/packages/**/*.tsx',
    './chameleon/**/*.stories.tsx',
  ],
  corePlugins: {
    preflight: false,
  },
  plugins: [require('./plugins/borders'), require('./plugins/color-vars')],
};
